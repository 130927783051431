import React, { useEffect, useState } from "react";
import Sidebar from "../../components/layout/Sidebar";
import Navbar from "../../components/nav";
import Library from "./page";
import axios from "axios";
import { get_base_uri } from "../../config";
const Layout = () => {
  const [libraryData, setLibraryData] = useState();

  const fetchLibraryVideo = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return;
    }
    const response = await axios.get(get_base_uri() + `/api/v1/library`, {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });
    setLibraryData(response.data.data);
  };

  useEffect(() => {
    fetchLibraryVideo();
  }, []);

  const deleteVideoHandler = async (id) => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return;
    }
    const response = await axios.patch(
      get_base_uri() + `/api/v1/library/${id}/remove/`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    fetchLibraryVideo();
  };
  return (
    <div className="flex overflow-hidden">
      <div className="max-lg:hidden">
        <Sidebar />
      </div>
      <div className="w-full">
        <Navbar />
        <div className="bg-[#F7F8FA] w-full h-[calc(100vh-85px)] overflow-y-auto pb-28 pt-10">
          <Library data={libraryData} deleteHandler={deleteVideoHandler} />
        </div>
      </div>
    </div>
  );
};

export default Layout;
