import React from 'react';

const DownloadSuccessful = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full gap-14 font-lato font-bold text-black text-2xl">
      <img
        src="/download-successful.svg"
        alt="Download Successful"
        width={154}
        height={154}
      />
      <p>Download Successfully</p>
    </div>
  );
};

export default DownloadSuccessful;
