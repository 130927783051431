import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Icons } from './ui/icons';
import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { NewInput } from './ui/NewInput';
import { get_base_uri } from '../config';

function UserAuthForm({ fetchStatus }) {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const navigate = useNavigate();

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const loginResponse = await axios.post(
        get_base_uri() + '/api/v1/user/login/',
        {
          username,
          password,
        }
      );

      console.log('Login Response:', loginResponse.data);
      const { access, refresh, message } = loginResponse.data;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);

      // Fetch user information using the access token
      const userInfoResponse = await axios.get(
        get_base_uri() + '/api/v1/user/info/',
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      );

      const userId = userInfoResponse.data.id; // Adjust this based on your actual response structure

      setAlertMessage(message || 'Login successful');
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error('Login Error:', error.response?.data || error.message);
      const errorMsg = error.response?.data?.detail || 'An error occurred';
      setAlertMessage(errorMsg);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } finally {
      setIsLoading(false);
      fetchStatus();
    }
  }

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <div className="flex flex-col gap-[38px] w-full">
          <div className="flex flex-col gap-[26px] w-full">
            <div className="flex flex-col gap-4 w-full">
              <NewInput
                type="text"
                placeholder="Enter Username"
                id="username"
                autoCapitalize="none"
                autoComplete="username"
                autoCorrect="off"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                disabled={isLoading}
              />
              <NewInput
                type="password"
                placeholder="Passcode"
                id="password"
                autoCapitalize="none"
                autoCorrect="off"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                disabled={isLoading}
              />
            </div>
            <p className="font-lato font-medium text-base text-black">
              Having trouble in sign in?
            </p>
          </div>
          <button
            disabled={isLoading}
            className="rounded-[4px] flex items-center justify-center font-lato font-medium text-white text-base py-4 bg-lpurple w-full active:scale-95 hover:scale-105 duration-300 disabled:bg-dark-gray"
            style={{ backgroundColor: 'purple', color: 'white' }}
          >
            {isLoading ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              'Sign in'
            )}
          </button>
        </div>
      </form>
      {showAlert && (
        <Alert>
          <AlertTitle>Response</AlertTitle>
          <AlertDescription>{alertMessage}</AlertDescription>
        </Alert>
      )}
    </div>
  );
}

export { UserAuthForm };
