import React from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import "../assets/style.css";

Modal.setAppElement("#root"); // Make sure to set the root element for accessibility

const VideoModal = ({ isOpen, closeModal, videoSrc }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Video Modal"
      className="fixed inset-0 z-20 flex items-center justify-center bg-gray-800 bg-opacity-75 p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-3xl w-full">
        <div className="flex justify-end p-2">
          <button
            onClick={closeModal}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            ✕
          </button>
        </div>
        <div className="p-4">
          <div className="w-full h-[70vh] video-box">
            <ReactPlayer
              url={videoSrc}
              playing={isOpen}
              controls={true}
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;
