import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Stages from "../../components/progress";
import { Button } from "../../components/ui/button";
import Variable from "./Variable";
import { logout } from "../../utils/logout";
import { LoadUserInfo } from "../../utils/userInfo";

import { get_base_uri } from "../../config";

export default function VariablesPage() {
  const navigate = useNavigate();
  const { ProjectId } = useParams();
  const decodedProjectId = decodeURIComponent(ProjectId);
  const [projectName, setProjectName] = useState("");
  const [project, setProject] = useState({});
  const [userId, setUserId] = useState("");
  const [currentSelection, setCurrentSelection] = useState(null);
  const [selections, setSelections] = useState([]);
  const [transcription, setTranscription] = useState([]);
  const [variables, setVariables] = useState(0);
  const [sampleRow, setSampleRow] = useState([]);
  const [status, setStatus] = useState("");
  const [activeMode, setActiveMode] = useState(false);

  const userInfoState = LoadUserInfo();

  useEffect(() => {
    if (
      userInfoState.loaded === "true" &&
      userInfoState.logged_out === "true"
    ) {
      navigate("/");
    }
  }, [userInfoState, navigate]);

  const isSelected = (num) => {
    const { start, end } = currentSelection || { start: 0, end: 0 };
    return num >= start && num <= end;
  };

  const insert = (num) => {
    let newSelection = {
      ...(currentSelection || {
        start: num,
        end: num,
        isAnchorStart: true,
      }),
    };
    const { start, end, isAnchorStart } = newSelection;

    if (num < start) {
      if (!isAnchorStart) {
        newSelection = {
          start: num,
          end,
          isAnchorStart: false,
        };
      } else {
        newSelection = {
          start: num,
          end: start,
          isAnchorStart: false,
        };
      }
    } else if (num > end) {
      if (isAnchorStart) {
        newSelection = {
          start,
          end: num,
          isAnchorStart: true,
        };
      } else {
        newSelection = {
          start: end,
          end: num,
          isAnchorStart: true,
        };
      }
    } else if (isAnchorStart) {
      newSelection = {
        start,
        end: num,
        isAnchorStart: true,
      };
    } else {
      newSelection = {
        start: num,
        end,
        isAnchorStart: false,
      };
    }

    if (
      selections.some((selection) => {
        if (!selection) return false;
        const { start: sStart, end: sEnd } = selection;
        return (
          (newSelection.start <= sStart && newSelection.end >= sStart) ||
          (newSelection.start <= sEnd && newSelection.end >= sEnd)
        );
      })
    )
      return;

    setCurrentSelection(newSelection);
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(
          `${get_base_uri()}/api/v1/projects/${decodedProjectId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
          }
        );
        const project = response.data;
        setProject(project);
        setProjectName(project.project_name);
        setUserId(project.user_id);
        setStatus(project.status);
        if (project.status === "transcription_done") {
          setActiveMode(true);
        }
        setTranscription(project.transcription.split(" "));
        setVariables(project.n_variables);
        setSampleRow(project.sample_row);
        setSelections(
          project.selected_variables && project.selected_variables.length
            ? project.selected_variables
            : new Array(project.n_variables).fill(null)
        );
      } catch (error) {}
    };
    fetchProject();
  }, [decodedProjectId]);

  const removeVariable = (i) => {
    setSelections((prev) => {
      const newSelections = [...prev];
      newSelections[i] = null;
      return newSelections;
    });
    setTranscription((prev) => {
      const newTranscription = [...prev];
      const { start, end } = selections[i];
      for (let j = start; j <= end; j++) {
        newTranscription[j] = sampleRow[i];
      }
      return newTranscription;
    });
  };

  const handleSubmit = () => {
    const sorted_selection = [...selections].sort((a, b) => a.start - b.start);

    let data = {
      status: "starting_generation",
      selected_variables: sorted_selection,
    };
    const token = localStorage.getItem("accessToken");
    axios
      .put(
        `${get_base_uri()}/api/v1/projects/${decodedProjectId}/update/status/`,
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(() => {
        navigate(`/project/${decodedProjectId}/download`);
      })
      .catch((error) => {});
  };

  const projectPage = () => {
    if (userId) {
      navigate(`/user/${userId}`);
    }
  };

  const [mouseDown, setMouseDown] = useState(false);

  const isVariable = (n) => {
    for (let i = 0; i < selections.length; i++) {
      const selection = selections[i];
      if (!selection) continue;
      const { start, end } = selection;
      if (n === start) {
        return i;
      } else if (n > start && n <= end) return -2;
    }
    return -1;
  };

  const addSelection = (i) => {
    setSelections((prev) => {
      const newSelections = [...prev];
      if (currentSelection) {
        newSelections[i] = currentSelection;
        const { start, end } = currentSelection;
        const newTranscription = [...transcription];
        for (let j = start; j <= end; j++) {
          newTranscription[j] = transcription[j]; // Mark the selection in the transcription
        }
        setTranscription(newTranscription);
      }
      return newSelections;
    });
    setCurrentSelection(null);
  };

  const getTranscriptionSlice = (selection) => {
    if (!selection) return "";
    const { start, end } = selection;
    const a = [...transcription];
    return a.slice(start, end + 1).join(" ");
  };

  return (
    <div className="relative h-screen flex flex-col bg-slate-100 items-center">
      <div className="w-50% flex flex-col items-center w-[50%]">
        <Stages progress={1} projectId={decodedProjectId} status={status} />
      </div>

      <div className="w-3/4 flex gap-5 mt-20 h-1/2">
        <div className="flex  w-1/4 flex-col items-center gap-3">
          <div className="flex flex-col w-full items-center">
            {sampleRow.map((variable, i) => {
              const selection = selections[i];
              return (
                <div
                  className="flex mt-[-2px] w-full items-center bg-white border-slate-500 p-2 gap-2 justify-start border-1"
                  key={i}
                >
                  <span
                    className={`text-sm px-1 rounded ${
                      selection ? "bg-mypurple text-white" : "bg-slate-200"
                    }`}
                  >
                    V{i + 1}
                  </span>
                  <span className="p-1 bg-slate-300 text-sm rounded">
                    {variable}
                  </span>
                  {selection === null ? (
                    <Button
                      className="ml-auto bg-mypurple hover:bg-lpurple"
                      onClick={() => addSelection(i)}
                    >
                      Add V{i + 1}
                    </Button>
                  ) : (
                    <>
                      <span>{"==>"}</span>
                      <span className="p-1 bg-slate-200 text-sm ml-auto rounded">
                        {getTranscriptionSlice(selection)}
                      </span>
                    </>
                  )}
                </div>
              );
            })}
          </div>

          {activeMode && (
            <Button
              className="w-48 bg-mypurple hover:bg-lpurple disabled:bg-slate-400"
              disabled={selections.some((selection) => !selection)}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </div>

        <div
          className="w-1/2 bg-slate-300 flex-grow rounded flex-wrap flex content-start overflow-auto p-3"
          onMouseUp={() => setMouseDown(false)}
          onMouseDown={(e) => e.preventDefault()}
        >
          {transcription.map((word, i) => {
            const variable = isVariable(i);

            if (variable === -1) {
              return (
                <span
                  onMouseEnter={() => {
                    mouseDown && insert(i);
                  }}
                  key={i}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    if (!activeMode) return;
                    if (currentSelection && isSelected(i)) {
                      setMouseDown(false);
                      setCurrentSelection(null);
                    } else if (selections.some((selection) => !selection)) {
                      setCurrentSelection({
                        start: i,
                        end: i,
                        isAnchorStart: true,
                      });
                      setMouseDown(true);
                    }
                  }}
                  className={`px-1 m-[0.05rem] cursor-pointer rounded ${
                    currentSelection && isSelected(i) ? "bg-lpurple" : ""
                  }`}
                >
                  {word}
                </span>
              );
            } else if (variable >= 0) {
              return (
                <Variable
                  key={i}
                  remove={removeVariable}
                  variable={variable}
                  activeMode={activeMode}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
