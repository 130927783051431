import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Stages from "../../components/progress";
import { AspectRatio } from "../../components/ui/aspect-ratio";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { Input } from "../../components/ui/input";
import { Progress } from "../../components/ui/progress";
import { Slider } from "../../components/ui/slider";
import Slider2 from "../../components/ui/slider2";
import { useParams } from "react-router-dom";
import { get_base_uri } from "../../config";
import { useNavigate } from "react-router-dom";
const UploadButton = ({
  uploaded,
  onChange,
  progress,
  image,
  label,
  accept,
  activeMode,
  cancel,
}) => {
  const inputRef = useRef(null);

  return (
    <div
      className={`flex group relative justify-center items-center cursor-pointer`}
      onClick={() => {
        if (!activeMode) return;
        uploaded || progress ? cancel() : inputRef.current.click();
      }}
    >
      <Input type="file" />
      <div
        className={`p-6 border w-[336px] h-[162px] border-upload-border rounded-[15px] flex flex-col gap-3 ${
          uploaded ? "bg-lpurple/30" : "bg-white hover:bg-white/80"
        }`}
      >
        {uploaded ? (
          <>
            <img src="/cross.png" alt="plus" height={30} width={30} />
            <span>Clear</span>
          </>
        ) : progress ? (
          <>
            <Progress
              className="z-20 w-3/4"
              indicatorColor="bg-mypurple"
              value={progress}
            />
            <span>{progress} %</span>
          </>
        ) : (
          <>
            <div className="group-hover:hidden">
              <img src={`/${image}`} alt="upload" height={50} width={50} />
              <div className="flex flex-col gap-0.5">
                <span className="font-lato font-bold text-black text-xl">
                  {label}
                </span>
                <span className="font-lato font-normal text-black text-sm">
                  Animate your photo with text
                </span>
              </div>
            </div>
            <div className="group-hover:block hidden w-[336px]">
              <img src="/plus.svg" alt="plus" height={50} width={50} />
              <div className="flex flex-col gap-0.5">
                <span className="font-lato font-bold text-black text-xl">
                  Upload
                </span>
                <span className="font-lato font-normal text-black text-sm">
                  Animate your photo with text
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const UploadPage = () => {
  const [progress, setProgress] = useState([0, 0, 0]);
  const videoRef = useRef(null);
  const [selfieVideoPath, setSelfieVideoPath] = useState("");
  const [recordingVideoPath, setRecordingVideoPath] = useState("");
  const [videoPosition, setVideoPosition] = useState([20]);
  const [videoPosition2, setVideoPosition2] = useState([0, 100]);
  const [checked, setChecked] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([false, false, false]);
  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("");
  const [activeMode, setActiveMode] = useState(true);
  const [project, setProject] = useState({});
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [transcribing, setTranscribing] = useState(false);

  const uploadLabels = ["Selfie Video", "Variables Sheet", "Screen Recording"];
  const images = ["selfie.svg", "table.svg", "screen_recording.svg"];
  const { ProjectId } = useParams();
  const [projectId, setProjectId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (ProjectId) {
      setProjectId(ProjectId);
    }
  }, [ProjectId]);

  useEffect(() => {
    const fetchProject = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await axios.get(
          get_base_uri() + `/api/v1/projects/${ProjectId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const project = response.data;
        setProject(project);
        setProjectName(project.project_name);
        setUserId(project.user_id);
        setStatus(project.status);

        if (project.status !== "awaiting_upload") setActiveMode(false);

        if (project.selfie_video) setSelfieVideoPath(project.selfie_video);
        if (project.screen_recording)
          setRecordingVideoPath(project.screen_recording);

        setUploadedFiles([
          !!project.selfie_video,
          !!project.variables_sheet,
          !!project.screen_recording,
        ]);

        if (project.status === "transcribing") {
          setTranscribing(true);
        }
      } catch (error) {}
    };
    fetchProject();
  }, [ProjectId]);

  const uploadFile = async (file, index) => {
    setUploadedFiles((prev) => {
      const newFiles = [...prev];
      newFiles[index] = false;
      return newFiles;
    });

    if (!file) {
      return;
    }

    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    formData.append("file", file);
    const fileFieldNames = [
      "selfie_video",
      "variables_sheet",
      "screen_recording",
    ];

    try {
      await axios.post(
        get_base_uri() + `/api/v1/projects/${projectId}/upload/`,
        { [fileFieldNames[index]]: file },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (evt) => {
            setProgress((prev) => {
              const newProgress = [...prev];
              const progress = Math.round((evt.loaded / evt.total) * 100);
              newProgress[index] = progress;
              return newProgress;
            });
          },
        }
      );
      setProgress((prev) => {
        const newProgress = [...prev];
        newProgress[index] = 0;
        return newProgress;
      });

      setUploadedFiles((prev) => {
        const newFiles = [...prev];
        newFiles[index] = true;
        return newFiles;
      });
    } catch (error) {}
  };

  const handleFileChange = (e, index) => {
    const file = e.target.files[0];
    if (index === 0) setSelfieVideoPath(URL.createObjectURL(file));
    if (index === 2) setRecordingVideoPath(URL.createObjectURL(file));
    uploadFile(file, index);
  };

  const timeToPosition = (time) => {
    const totalTime = videoRef.current?.duration;
    return totalTime ? (time / totalTime) * 100 : 0;
  };

  const positionToTime = (position) => {
    const totalTime = videoRef.current?.duration;
    return Math.round(position * (totalTime || 1)) / 100;
  };

  const positionToTimeString = (position) => {
    const time = positionToTime(position);
    const minutes = Math.floor(time / 60);
    const seconds = Math.round((time % 60) * 100) / 100;
    return `${minutes ? minutes + " min " : ""}${
      seconds < 10 ? "0" : ""
    }${seconds} seconds`;
  };

  const handleSubmit = async () => {
    setTranscribing(true);
    const data = {
      status: "in_progress",
    };
    const token = localStorage.getItem("accessToken");

    try {
      await axios.put(
        get_base_uri() + `/api/v1/projects/${projectId}/update/status/`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const checkStatusInterval = setInterval(async () => {
        const projectResponse = await axios.get(
          get_base_uri() + `/api/v1/projects/${projectId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const updatedProject = projectResponse.data;
        setStatus(updatedProject.status);

        if (updatedProject.status === "transcription_done") {
          clearInterval(checkStatusInterval);
          navigate(`/project/${projectId}/variables`);
          setTranscribing(false);
        }
      }, 5000); // Check every 5 seconds
    } catch (error) {
      setTranscribing(false);
    }
  };

  const handleCancel = (index) => {
    setProgress((prev) => {
      const newProgress = [...prev];
      newProgress[index] = 0;
      return newProgress;
    });
    setUploadedFiles((prev) => {
      const newFiles = [...prev];
      newFiles[index] = false;
      return newFiles;
    });
    index === 0 && setSelfieVideoPath("");
    index === 2 && setRecordingVideoPath("");
  };

  const [playing, setPlaying] = useState(false);

  return (
    <div className="relative flex flex-col bg-white items-center">
      <div className="w-50% flex flex-col items-center w-[50%]">
        <Stages progress={0} projectId={userId} status={status} />
      </div>
      <div className="flex flex-col gap-[38px] w-full items-center max-lg:px-4">
        <div className="w-full max-w-[839px] border border-black rounded-[14px] mt-36">
          <input
            className="px-2 py-4 w-full bg-transparent focus:first:outline-none"
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            placeholder="Choose a name for your project"
            disabled
          />
        </div>
        <div className="w-[50%] flex flex-col">
          <AspectRatio
            ratio={16 / 9}
            className="bg-slate-200 flex flex-col justify-center items-center"
          >
            {recordingVideoPath || selfieVideoPath ? (
              <video
                onTimeUpdate={(event) => {
                  !videoRef.current?.paused &&
                    setVideoPosition([
                      timeToPosition(event.target.currentTime),
                    ]);
                }}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                onClick={() =>
                  videoRef.current?.paused
                    ? videoRef.current?.play()
                    : videoRef.current?.pause()
                }
                ref={videoRef}
                src={recordingVideoPath || selfieVideoPath}
              />
            ) : (
              <div className="flex flex-col gap-5 items-center">
                <span className="text-lg">
                  {loadingVideo ? "Loading..." : "Upload a video to preview"}
                </span>
              </div>
            )}
          </AspectRatio>
          {(!!recordingVideoPath || !!selfieVideoPath) && (
            <div className="flex gap-3 ml-4 mr-4 mt-4 items-center">
              <Button
                className="bg-mypurple hover:bg-lpurple"
                onClick={() => {
                  playing
                    ? videoRef?.current?.pause()
                    : videoRef?.current?.play();
                }}
              >
                <img
                  src={playing ? "/pause.png" : "/play.webp"}
                  height={playing ? 15 : 20}
                  width={playing ? 15 : 20}
                  alt={playing ? "Pause" : "Play"}
                />
              </Button>
              <div className="relative w-full">
                <Slider
                  className="absolute top-1/2 -translate-y-1/2"
                  disabled={!activeMode}
                  onValueChange={(value) =>
                    setVideoPosition((prev) => {
                      if (videoRef?.current) {
                        videoRef.current.pause();
                        videoRef.current.currentTime = positionToTime(value[0]);
                      }
                      return value;
                    })
                  }
                  value={videoPosition}
                  max={100}
                  step={0.05}
                  checked={checked}
                />
                {checked && (
                  <Slider2
                    className="absolute top-1/2 -translate-y-1/2"
                    onValueChange={(value) =>
                      setVideoPosition2((prev) => {
                        const [start, _] = prev;
                        const [newStart, newEnd] = value;
                        if (videoRef?.current) {
                          videoRef.current.pause();
                          if (newStart !== start) {
                            setVideoPosition([newStart]);
                            videoRef.current.currentTime =
                              positionToTime(newStart);
                          } else {
                            setVideoPosition([newEnd]);
                            videoRef.current.currentTime =
                              positionToTime(newEnd);
                          }
                        }
                        return value;
                      })
                    }
                    minStepsBetweenThumbs={2}
                    disabled={!activeMode}
                    value={videoPosition2}
                    max={100}
                    step={0.05}
                    checked={checked}
                  />
                )}
              </div>
            </div>
          )}
          {!!recordingVideoPath && (
            <>
              <div className="flex items-center gap-2 mt-4 mb-2 ml-4">
                <Checkbox
                  checked={checked}
                  onCheckedChange={(value) => {
                    if (!value) {
                      setVideoPosition([0, 100]);
                      setChecked(false);
                    } else {
                      setChecked(true);
                    }
                  }}
                />
                <span>Do you want to add background video?</span>
              </div>
              {checked && (
                <>
                  <span className="ml-4">
                    Start time: {positionToTimeString(videoPosition2[0])}
                  </span>
                  <span className="ml-4">
                    End time: {positionToTimeString(videoPosition2[1])}
                  </span>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className="max-w-[1098px] w-full mt-10 mb-20 flex items-center justify-center flex-wrap gap-[30px]">
        {[0, 1, 2].map((index) => (
          <UploadButton
            activeMode={activeMode}
            key={index}
            uploaded={uploadedFiles[index]}
            onChange={(e) => activeMode && handleFileChange(e, index)}
            progress={progress[index]}
            image={images[index]}
            label={uploadLabels[index]}
            accept={index === 1 ? ".csv, .xlsx" : ".mp4, .mkv"}
            cancel={() => handleCancel(index)}
          />
        ))}
      </div>
      <button
        className={`mb-20 ${
          transcribing ? "bg-slate-400" : "bg-lpurple hover:bg-lpurple/80"
        } flex items-center gap-6 py-[18px] px-[54px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] rounded-[32px]`}
        disabled={
          !activeMode ||
          !(uploadedFiles[0] && uploadedFiles[1] && uploadedFiles[2]) ||
          transcribing
        }
        onClick={handleSubmit}
      >
        {transcribing ? (
          <span className="font-lato font-bold text-white md:text-2xl lg:text-[37px]">Transcribing...</span>
        ) : (
          <>
            <img
              className="max-lg:w-[40px] h-auto"
              src="/mic.svg"
              width={97}
              height={72}
              alt="mic"
            />
            <span className="font-lato font-bold text-white md:text-2xl lg:text-[37px]">
              Transcribe the video
            </span>
          </>
        )}
      </button>
    </div>
  );
};

export default UploadPage;
