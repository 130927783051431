import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SignUpForm } from "../components/signUp";
import { UserAuthForm } from "../components/user-auth-form";
import { get_base_uri } from "../config";

export default function AuthenticationPage() {
  const [loginScreen, setLoginScreen] = useState(true);
  const [userName, setUserName] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      // Assuming you have a user endpoint to verify the token and get the user info
      axios
        .get(get_base_uri() + "/api/v1/user/info/", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          const userInfo = response.data;
          // localStorage.setItem('userInfo', JSON.stringify(userInfo));
          const { username } = userInfo;
          navigate(`/`);
        })
        .catch((error) => {});
    } else {
      fetchStatus();
    }
  }, [navigate]);

  const fetchStatus = async () => {
    try {
      const response = await axios.get(get_base_uri() + "/api/v1/");
      setUserName(response.data.username);
    } catch (error) {
      setUserName(null);
    }
  };

  return (
    <main className="w-full flex items-center justify-center">
      <div className="max-w-[554px] w-full rounded-xl md:rounded-[43px] shadow-[0_0_40px_rgba(0,0,0,0.10)] flex flex-col items-center px-4 py-8 sm:px-[60px] sm:py-[65px]">
        <div className="flex flex-col gap-[26px] w-full items-center mb-[53px]">
          <a href="/">
            <img src="/logo-2.svg" width={194} height={53} alt="logo" />
          </a>
          <h3 className="font-lato font-bold text-[35px] text-black">
            {loginScreen ? "Login" : "Sign Up"}
          </h3>
          <p className="max-w-[278px] font-lato font-medium text-base text-black text-center w-full">
            Hey, Enter your details to get {loginScreen ? "Sign In" : "Sign Up"}{" "}
            to your account
          </p>
        </div>
        {loginScreen ? (
          <UserAuthForm fetchStatus={fetchStatus} />
        ) : (
          <SignUpForm setLoginScreen={setLoginScreen} />
        )}

        <div className="mt-[38px] flex flex-col items-center gap-8 font-lato font-medium text-base text-black">
          <p>Or Sign in with</p>
          <div className="flex items-center gap-3">
            <div className="w-[61px] h-[55px] flex items-center justify-center border border-input-border rounded-[10px]">
              <img src="/google.svg" width={48} height={48} alt="google" />
            </div>
            <div className="w-[61px] h-[55px] flex items-center justify-center border border-input-border rounded-[10px]">
              <img src="/fb.svg" width={48} height={48} alt="fb" />
            </div>
            <div className="w-[61px] h-[55px] flex items-center justify-center border border-input-border rounded-[10px]">
              <img src="/apple.svg" width={48} height={48} alt="apple" />
            </div>
          </div>
          <p>
            {loginScreen ? (
              <>
                Don’t have an account?{" "}
                <span
                  onClick={() => setLoginScreen(!loginScreen)}
                  className="cursor-pointer text-lpurple hover:underline"
                >
                  Request Now
                </span>
              </>
            ) : (
              <>
                Already have an account?{" "}
                <span
                  onClick={() => setLoginScreen(!loginScreen)}
                  className="cursor-pointer text-lpurple hover:underline"
                >
                  Login Now
                </span>
              </>
            )}
          </p>
        </div>
      </div>
    </main>
  );
}
