import React from 'react';
import { useNavigate } from 'react-router-dom'; 
const Circle = ({ width = 25, height = 25, selected = false } = {}) => {
  return (
    <svg
      className={`-m-1 fill-current ${selected ? 'text-mypurple' : 'text-gray-300'}`}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 15 15"
    >
      <path d="M14,7.5c0,3.5899-2.9101,6.5-6.5,6.5S1,11.0899,1,7.5S3.9101,1,7.5,1S14,3.9101,14,7.5z" />
    </svg>
  );
};

const Progress = ({ projectId, status, labels = ["Upload", "Set Variables", "Download"], progress }) => {
  const navigate = useNavigate();

  const navigateToProject = (project_id, page) => {
    navigate(`/project/${project_id}/${page}`);
  };

  const getCallback = (index) => {
    if (index === 0) {
      return () => navigateToProject(projectId, 'upload');
    } else if (index === 1) {
      return () =>
        (status === 'transcription_done' || status === 'generation_done') &&
        navigateToProject(projectId, 'variables');
    } else {
      return () =>
        status === 'generation_done' &&
        navigateToProject(projectId, 'download');
    }
  };

  const getCursorClass = (index) => {
    if (index === 0) {
      return 'cursor-pointer';
    } else if (index === 1) {
      return status === 'transcription_done' || status === 'generation_done'
        ? 'cursor-pointer'
        : 'cursor-not-allowed';
    } else {
      return status === 'generation_done'
        ? 'cursor-pointer'
        : 'cursor-not-allowed';
    }
  };

  return (
    <div className="flex w-3/4 flex-row items-center mt-20">
      {labels.map((label, index) => {
        if (index === 0) {
          return (
            <div
              className={`relative z-10 ${getCursorClass(index)}`}
              key={index}
              onClick={getCallback(index)}
            >
              <Circle selected />
              <span className="absolute text-center transform top-6 -translate-x-1/2 left-1/2">
                {label}
              </span>
            </div>
          );
        }
        return (
          <React.Fragment key={index}>
            <div
              className={`${index <= progress ? 'bg-mypurple' : 'bg-gray-300'} h-2 flex-grow`}
            />
            <div
              className={`relative z-10 ${getCursorClass(index)}`}
              onClick={getCallback(index)}
            >
              <Circle selected={index <= progress} />
              <span className="absolute text-center transform top-6 -translate-x-1/2 left-1/2">
                {label}
              </span>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Progress;
