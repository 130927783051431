import axios from "axios";
import Image from "./Image";
import { get_base_uri } from "../../config";
import { redirect } from "react-router-dom";

type Props = {
  title: string,
  price: number,
  description: string,
  isPopular?: boolean,
  features: string[],
};

const PlanCard = (props: Props) => {
  const token = localStorage.getItem("accessToken");
  const createSession = async () => {
    try {
      const response = await axios.post(
        get_base_uri() + `/api/v1/billing/create-checkout-session/`,
        {
          planId: props.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      window.location.href = response.data.url;
    } catch (error) {}
  };
  return (
    <div
      className={`max-w-[308px] w-full shadow-[0_0_20px_rgba(0,0,0,0.10)] rounded-[15px] ${
        props.isPopular ? "bg-purple-600" : "bg-white"
      }`}
    >
      {props.isPopular && (
        <div className="bg-purple-600 pt-8 pb-2 rounded-t-[15px] font-lato font-normal text-lg text-white text-center">
          MOST POPULAR
        </div>
      )}
      <div className={`w-full flex flex-col gap-[26px] py-[37px] px-[27px]`}>
        <div
          className={`font-lato font-normal  flex flex-col gap-3  ${
            props.isPopular ? "text-white" : "text-black"
          }`}
        >
          <h3 className="text-lg ">{props.title}</h3>
          <p className="text-4xl">
            ${props.price}
            <sup className="text-[18px]">/MO</sup>
          </p>
          <p className="text-sm">{props.description}</p>
        </div>
        <div className="flex flex-col gap-[19px]">
          {
            // loop through the features
            props.features.map((feature, index) => (
              <IconCard
                isPopular={props.isPopular}
                key={index}
                icon="/tick.svg"
                title={feature}
              />
            ))
          }
        </div>
        <div className="flex items-center justify-center">
          <button
            disabled={
              props.status &&
              props.status.active === true &&
              props.status.plan.id === props.id
            }
            onClick={createSession}
            className={`active:scale-105 hover:scale-95 duration-300 max-w-fit w-full rounded-[10px]
          flex py-3.5 px-[28px]  flex-col items-center justify-center ${
            props.isPopular ? "bg-white text-black" : "bg-purple-600 text-white"
          }`}
          >
            <span className="text-[13px] leading-none">{props.name}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;

// define the types
type IconCardProps = {
  icon: string,
  title: string,
  isPopular?: boolean,
};
function IconCard(props: IconCardProps) {
  return (
    <div className="flex items-center gap-2.5">
      <Image src={props.icon} width={16} height={16} alt="icon" />
      <h3
        className={`font-lato text-[13px] font-normal  ${
          props.isPopular ? "text-white" : "text-black"
        }`}
      >
        {props.title}
      </h3>
    </div>
  );
}
