import axios from "axios";
import { useEffect, useState } from "react";
import { get_base_uri } from "../config";

export const LoadUserInfo = () => {
  const [data, setData] = useState({
    logged_out: "true",
    loaded: "false",
  });

  axios.defaults.withCredentials = true;

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      axios
        .get(get_base_uri() + "/api/v1/user/info/", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setData({ logged_out: "false", loaded: "true", ...response.data });
        });
    } else {
    }
  }, []);

  return data;
};
