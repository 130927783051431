import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Icons } from "./ui/icons"; // Adjust the import path according to your project structure
import { Alert, AlertDescription, AlertTitle } from "./ui/alert"; // Adjust the import path according to your project structure
import { NewInput } from "./ui/NewInput"; // Adjust the import path according to your project structure
import { get_base_uri } from "../config";

export function SignUpForm({ className, setLoginScreen, ...props }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");

  async function onSubmit(event) {
    event.preventDefault();

    if (password !== confirmPassword) {
      setAlertMessage("Passwords do not match");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(
        get_base_uri() + "/api/v1/user/signup/",
        {
          username,
          email,
          password,
        }
      );

      setAlertMessage(response.data.message || "Signup successful");
      setLoginScreen(true);
    } catch (error) {
      let errorMsg = "An error occurred";
      if (error.response?.data) {
        const errorData = error.response.data;
        errorMsg = Object.values(errorData).flat().join(" ");
      }
      setAlertMessage(errorMsg);
    } finally {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      setIsLoading(false);
    }
  }

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <div className="flex flex-col gap-[38px] w-full">
          <div className="flex flex-col gap-[26px] w-full">
            <div className="flex flex-col gap-4 w-full">
              <NewInput
                type="text"
                id="username"
                placeholder="Username"
                autoCapitalize="none"
                autoComplete="username"
                autoCorrect="off"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                disabled={isLoading}
              />
              <NewInput
                type="email"
                id="email"
                placeholder="Email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                disabled={isLoading}
              />
              <NewInput
                type="password"
                id="password"
                placeholder="Password"
                autoCapitalize="none"
                autoCorrect="off"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                disabled={isLoading}
              />
              <NewInput
                id="confirm-password"
                placeholder="Confirm Password"
                type="password"
                autoCapitalize="none"
                autoCorrect="off"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                disabled={isLoading}
              />
            </div>
          </div>
          <button
            disabled={isLoading}
            className="rounded-[10px] flex items-center justify-center font-lato font-medium text-white text-base py-4 bg-lpurple w-full active:scale-95 hover:scale-105 duration-300"
            style={{ backgroundColor: "purple", color: "white" }}
          >
            {isLoading ? (
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              "Sign up"
            )}
          </button>
        </div>
      </form>
      {showAlert && (
        <Alert>
          <AlertTitle>Response</AlertTitle>
          <AlertDescription>{alertMessage}</AlertDescription>
        </Alert>
      )}
    </div>
  );
}

SignUpForm.propTypes = {
  className: PropTypes.string,
  setLoginScreen: PropTypes.func.isRequired,
};

SignUpForm.defaultProps = {
  className: "",
};
