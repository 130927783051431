import React from 'react';
import Sidebar from '../../components/layout/Sidebar';
import Navbar from '../../components/nav';
import Plan from './page'
const Layout = () => {
  return (
    <div className="flex overflow-hidden">
      <div className="max-lg:hidden">
        <Sidebar />
      </div>
      <div className="w-full">
        <Navbar />
        <div className="bg-[#F7F8FA] w-full h-[calc(100vh-85px)] overflow-y-auto pb-28 pt-10">
          <Plan/>
        </div>
      </div>
    </div>
  );
};

export default Layout;
