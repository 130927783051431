import React, { useState } from 'react';

const Variable = ({ index, remove, variable, activeMode }) => {
  const [hover, setHover] = useState(false);
  return (
    <span
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={index}
      className="px-1 cursor-pointer rounded bg-[#C1B5FF] relative text-black flex items-center justify-center"
      onClick={() => activeMode && remove(variable)}
    >
      {hover && activeMode && (
        <img
          className="absolute -top-1 -right-1 bg-blue-50 rounded-full"
          src="/cross.png"
          alt="cross"
          width={8}
          height={8}
        />
      )}
      <span className="h-[20px]">{`V${variable + 1}`}</span>
    </span>
  );
};

export default Variable;
