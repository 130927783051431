import React from 'react';

const SubscriptionIcon = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${active ? "fill-lpurple" : "fill-black"} hover:fill-purple transition-all duration-300`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 6H20V8H4V6ZM6 2H18V4H6V2ZM20 10H4C3.46957 10 2.96086 10.2107 2.58579 10.5858C2.21071 10.9609 2 11.4696 2 12V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V12C22 11.4696 21.7893 10.9609 21.4142 10.5858C21.0391 10.2107 20.5304 10 20 10ZM15 17H13V19H11V17H9V15H11V13H13V15H15V17Z" />
    </svg>
  );
};

export default SubscriptionIcon;
