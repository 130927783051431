import * as React from 'react';

const NewInput = React.forwardRef(function NewInput({ className, type, ...props }, ref) {
  return (
    <input
      type={type}
      className="w-full py-4 px-7 rounded-[10px] border border-input-border placeholder:text-black placeholder:text-base placeholder:font-lato font-lato"
      ref={ref}
      {...props}
    />
  );
});

NewInput.displayName = "NewInput";

export { NewInput };
