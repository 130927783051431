import React from 'react';

const HomeIcon = ({ active }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      className={`${active ? "fill-lpurple" : "fill-black"} hover:fill-black transition-all duration-300`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.1838 10.8358L11.5299 1.18895C11.4604 1.1193 11.3778 1.06404 11.2869 1.02634C11.196 0.988644 11.0986 0.969238 11.0002 0.969238C10.9018 0.969238 10.8044 0.988644 10.7135 1.02634C10.6226 1.06404 10.54 1.1193 10.4705 1.18895L0.816602 10.8358C0.535352 11.1171 0.375977 11.4991 0.375977 11.8975C0.375977 12.7249 1.04863 13.3975 1.87598 13.3975H2.89316V20.2811C2.89316 20.696 3.22832 21.0311 3.64316 21.0311H9.50019V15.7811H12.1252V21.0311H18.3572C18.7721 21.0311 19.1072 20.696 19.1072 20.2811V13.3975H20.1244C20.5229 13.3975 20.9049 13.2405 21.1861 12.9569C21.7697 12.371 21.7697 11.4218 21.1838 10.8358Z" />
    </svg>
  );
};

export default HomeIcon;
