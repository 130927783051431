import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './app/page'; 
import User from './app/user/layout';
import UploadProject from './app/projects/upload';
import Coins from './app/credits/page';
import PaymentPage from './app/credits/payment';
import Library from './app/library/layout';
import Package from './app/packages/layout';
import DownloadPage from './app/projects/download';
import VariablePage from './app/projects/VariablesPage';
import NotFound from './NotFound';

const isTokenExpired = (token) => {
  if (!token) return true;
  const payload = JSON.parse(atob(token.split('.')[1]));
  const expiry = payload.exp;
  const now = Math.floor(Date.now() / 1000);
  return now >= expiry;
};

const PrivateRoute = ({ element }) => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken && !isTokenExpired(accessToken) ? element : <Navigate to="/login" />;
};

const PublicRoute = ({ element }) => {
  const accessToken = localStorage.getItem('accessToken');
  return accessToken && !isTokenExpired(accessToken) ? <Navigate to="/" /> : element;
};

function App() {
  const accessToken = localStorage.getItem('accessToken');
  const userId = accessToken && !isTokenExpired(accessToken)
    ? JSON.parse(atob(accessToken.split('.')[1])).user_id
    : null;

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<PublicRoute element={<Home />} />} />
          <Route path="/" element={accessToken && !isTokenExpired(accessToken) ? <User userId={userId} /> : <Navigate to="/login" />} />
          <Route path="/project/:ProjectId/upload" element={<PrivateRoute element={<UploadProject />} />} />
          <Route path="/project/:ProjectId/download" element={<DownloadPage />} />
          <Route path="/project/:ProjectId/variables" element={<VariablePage />} />
          <Route path="/library" element={<Library />} />
          <Route path="/packages" element={<Package />} />
          <Route path="/credits/" element={<PrivateRoute element={<Coins />} />} />
          <Route path="/payment" element={<PrivateRoute element={<PaymentPage />} />} />
          <Route path="*" element={<NotFound />} />  {/* Catch-all route for undefined routes */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
