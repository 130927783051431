import * as React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { cn } from '../../lib/utils'; // Adjust the import path according to your project structure

const Slider = React.forwardRef(({ className, checked, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative w-full grow h-10 overflow-hidden rounded">
      <SliderPrimitive.Range className="absolute h-10" />
    </SliderPrimitive.Track>

    <div className="absolute h-2 w-full bg-mypurple rounded"></div>

    <SliderPrimitive.Thumb
      className={
        'block h-10 w-1 z-20 relative rounded-full border-2 border-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50'
      }
    />
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
