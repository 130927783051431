"use client";
import VideoModal from "../../components/VideoModal";
import Image from "../../components/ui/Image";
import ConfirmationPopup from "../../components/ui/confirmationPopup";
import React, { useState, useEffect } from "react";

const Page = ({ data, deleteHandler }) => {
  const selfieVideos = data?.filter(item => item.video_type === "selfie");
  const screenRecordings = data?.filter(item => item.video_type === "screen");
  const [delModal, setDelModal] = useState(false);
  const [deleteAbleId, setDeleteAbleId] = useState(null);

  const modalIsOpen = (id) => {
    setDeleteAbleId(id);
    setDelModal(true);
  };

  const deleteLibHandler = () => {
    if (deleteAbleId) {
      deleteHandler(deleteAbleId);
      setDelModal(false);
      setDeleteAbleId(null);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 space-y-16">
      <VideoSection
        title="Selfie Videos"
        videos={selfieVideos}
        deleteHandler={deleteHandler}
        modalIsOpen={modalIsOpen}
      />
      <VideoSection
        title="Screen Recordings"
        videos={screenRecordings}
        deleteHandler={deleteHandler}
        modalIsOpen={modalIsOpen}
      />
      <ConfirmationPopup openModal={delModal} setOpenModal={setDelModal}>
        <p>Are you sure you want to delete this Video?</p>
        <div className="flex justify-center space-x-4 mt-10">
          <button
            onClick={deleteLibHandler}
            className="px-4 py-2 bg-red-600 text-white rounded-md"
          >
            Agree
          </button>
          <button
            onClick={() => setDelModal(false)}
            className="px-4 py-2 bg-gray-300 rounded-md"
          >
            Cancel
          </button>
        </div>
      </ConfirmationPopup>
    </div>
  );
};

export default Page;

const VideoSection = ({ title, videos, deleteHandler, modalIsOpen }) => (
  <div className="p-6 bg-white shadow-md rounded-lg">
    <h2 className="text-3xl font-bold mb-6 text-black-700 border-b pb-3">{title}</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
      {videos?.length > 0 ? (
        videos.map((item, index) => (
          <LibraryCard key={index} item={item} deleteItemHandler={deleteHandler} modalIsOpen={modalIsOpen} />
        ))
      ) : (
        <div className="col-span-full text-center text-gray-500">
          No videos available
        </div>
      )}
    </div>
  </div>
);

function LibraryCard({ item, deleteItemHandler, modalIsOpen }) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const getFileName = (url) => {
    try {
      const path = new URL(url).pathname;
      return path.substring(path.lastIndexOf('/') + 1).split('.')[0];
    } catch (error) {
      return item.title;
    }
  };

  return (
    <div className="relative hover:-translate-y-2 duration-300 group w-full px-3.5 py-[9px] cursor-pointer rounded-2xl hover:bg-white rounded-lg hover:shadow-[0_0_10px_rgba(0,0,0,0.25)]">
      <div className="absolute right-4 top-4 items-center gap-2 hidden z-20 group-hover:flex flex-col">
        <div
          onClick={() => modalIsOpen(item.id)}
          className="size-7 rounded-[6px] bg-white flex items-center justify-center cursor-pointer transition duration-200 hover:bg-lpurple hover:text-white"
        >
          <img src="/trash.svg" alt="trash" height={20} width={16} />
        </div>
      </div>
      <div className="relative">
        <img
          src={item.thumbnail}
          alt="project thumbnail"
          className="h-48 w-full object-cover"
        />
        <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white text-xs px-2 py-1 rounded">
          {item.duration}
        </div>
        <div className="flex items-center justify-center w-full px-3 absolute bottom-3">
          <button onClick={openModal}>
            <Image
              className="play-btn"
              src="/play-btn.svg"
              alt="play-btn"
              height={40}
              width={40}
            />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-start p-2 w-full">
        <span className="text-lg font-medium">{getFileName(item.video_file)}</span>
      </div>
      <VideoModal
        isOpen={isOpen}
        closeModal={closeModal}
        videoSrc={item.video_file}
      />
    </div>
  );
}
