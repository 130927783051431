import React from 'react';
import PropTypes from 'prop-types';
import cn from '../../lib/utils';

const Image = ({ src, alt, width,height, className, showImage,remove, variable }) => {
  if (!showImage) {
    return null;
  }

  return (
    <img
    
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={cn('object-cover', className)}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  showImage: PropTypes.bool,
};

Image.defaultProps = {
  width: undefined,
  height: undefined,
  className: '',
  showImage: true,
};

export default Image;
