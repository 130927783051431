import React from 'react';
import Sidebar from '../../components/layout/Sidebar';
import Navbar from '../../components/nav';
import Page from './[user_id]/page'
const Layout = ({ children }) => {
  return (
    <html lang="en">
      <body className="flex overflow-hidden">
        <div className="max-lg:hidden">
          <Sidebar />
        </div>
        <div className="w-full">
          <Navbar />
          <Page />
          {/* <div className="bg-[#F7F8FA] w-full h-[calc(100vh-85px)] overflow-y-auto pb-28 pt-10">
            {children}
          </div> */}
        </div>
      </body>
    </html>
  );
};

export default Layout;
