import React, { useEffect, useState } from "react";
import PlanCard from "../../components/ui/PlanCard";
import { logout } from "../../utils/logout";
import { get_base_uri } from "../../config";
import axios from "axios";

const Page = () => {
  const [response, setResponse] = useState([]);
  const [status, setStatus] = useState();
  useEffect(() => {
    const fetchPlans = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        return;
      }
      const response = await axios.get(
        get_base_uri() + `/api/v1/billing/plans`,
        {
          headers: { Authorization: `Bearer ${token}` },
          // withCredentials: true,
        }
      );
      setResponse(response.data);
    };
    const subscriptionStatus = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        return;
      }
      const response = await axios.get(
        get_base_uri() + `/api/v1/billing/subscription-status/`,
        {
          headers: { Authorization: `Bearer ${token}` },
          // withCredentials: true,
        }
      );
      setStatus(response.data);
      // setResponse(response.data);
    };
    subscriptionStatus();
    fetchPlans();
  }, []);

  const token = localStorage.getItem("accessToken");
  const cancelSubscription = async () => {
    try {
      const response = await axios.post(
        get_base_uri() + `/api/v1/billing/cancel-subscription/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        setStatus();
      }
    } catch (error) {}
  };

  const planCardData = [
    {
      title: "Starter",
      price: 19,
      description: "Basic use for beginners",
      features: [
        "All limited links",
        "Unlimited users",
        "End to end encryption",
        "Chat support",
        "Own analytics platforms",
      ],
    },
    {
      title: "Pro",
      price: 39,
      isPopular: true,
      description: "For medium teams or office",
      features: [
        "All limited links",
        "Unlimited users",
        "End to end encryption",
        "Chat support",
        "Own analytics platforms",
      ],
    },
    {
      title: "Enterprise",
      price: 99,
      description: "For large teams or office",
      features: [
        "All limited links",
        "Unlimited users",
        "End to end encryption",
        "Chat support",
        "Own analytics platforms",
      ],
    },
  ];

  return (
    <div className="container mt-10">
      <div className="flex flex-col gap-11 items-center justify-center w-full">
        <h2 className="font-lato font-normal text-4xl xl:text-[70px]">
          Choose your plan
        </h2>
        <div className="flex items-center justify-center w-full flex-wrap">
          {
            // loop through the planCardData
            response.length > 0 &&
              response.map((data, index) => {
                if (index === 1) {
                  return (
                    <PlanCard
                      key={index}
                      id={data.id}
                      title={data.title}
                      name={data.name}
                      status={status}
                      price={data.amount}
                      description={data.description}
                      features={data.features.split(", ")}
                      isPopular={true}
                    />
                  );
                } else {
                  return (
                    <PlanCard
                      key={index}
                      id={data.id}
                      title={data.title}
                      name={data.name}
                      status={status}
                      price={data.amount}
                      description={data.description}
                      features={data.features.split(", ")}
                      isPopular={data.isPopular}
                    />
                  );
                }
              })
          }
        </div>
        {status && status.active === true && (
          <p>
            You have selected <b>{status.plan.name} </b>plan if you want to
            cancel subscription then click on{" "}
            <button
              className="text-red-500 font-bold"
              onClick={cancelSubscription}
            >
              Cancel
            </button>
          </p>
        )}
      </div>
    </div>
  );
};

export default Page;
