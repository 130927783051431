import React from "react";

export const ConfirmationPopup = ({ openModal, setOpenModal, children }) => {
  return (
    <div
      onClick={() => setOpenModal(false)}
      className={`fixed z-[100] flex items-center justify-center ${
        openModal ? "visible opacity-100" : "invisible opacity-0"
      } inset-0 bg-black/20 backdrop-blur-sm duration-300`}
    >
      <div
        onClick={(e_) => e_.stopPropagation()}
        className={`duration-300 relative bg-white px-16 py-12 max-w-[500px] h-fit overflow-y-auto rounded-[26px] ${
          openModal ? "scale-1 opacity-1" : "scale-0 opacity-0"
        }`}
        style={{
          maxHeight: "80vh", // Limit max height to 80vh to prevent overflow
          width: "70%", // Adjust width as needed
          maxWidth: "600px", // Limit max width to 600px
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center align the content horizontally
        }}
      >
        <svg
          onClick={() => setOpenModal(false)}
          className="absolute top-4 right-4 cursor-pointer"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.3398 8.32205C15.4358 8.23249 15.5133 8.12488 15.5678 8.00538C15.6223 7.88587 15.6527 7.75681 15.6573 7.62555C15.6619 7.4943 15.6406 7.36342 15.5946 7.2404C15.5486 7.11737 15.4788 7.00461 15.3893 6.90855C15.2997 6.81249 15.1921 6.73501 15.0726 6.68053C14.9531 6.62605 14.824 6.59565 14.6928 6.59105C14.5615 6.58646 14.4306 6.60776 14.3076 6.65374C14.1846 6.69972 14.0718 6.76949 13.9758 6.85905L11.0498 9.58705L8.32176 6.66005C8.13923 6.47309 7.89078 6.36501 7.62956 6.35891C7.36834 6.35282 7.11512 6.44921 6.92407 6.62745C6.73302 6.8057 6.61932 7.05164 6.6073 7.31265C6.59529 7.57366 6.6859 7.829 6.85977 8.02405L9.58777 10.95L6.66077 13.6781C6.56131 13.7667 6.48057 13.8743 6.4233 13.9946C6.36602 14.1149 6.33336 14.2454 6.32723 14.3785C6.3211 14.5116 6.34163 14.6446 6.38761 14.7696C6.43359 14.8947 6.5041 15.0093 6.59498 15.1067C6.68587 15.2041 6.79531 15.2824 6.91687 15.3369C7.03843 15.3915 7.16966 15.4212 7.30286 15.4243C7.43605 15.4274 7.56853 15.4038 7.6925 15.355C7.81647 15.3062 7.92944 15.2331 8.02476 15.14L10.9508 12.413L13.6788 15.3391C13.7668 15.4403 13.8744 15.5229 13.9951 15.5817C14.1157 15.6405 14.247 15.6745 14.381 15.6815C14.5151 15.6885 14.6492 15.6685 14.7753 15.6226C14.9015 15.5767 15.0171 15.5058 15.1153 15.4143C15.2134 15.3228 15.2922 15.2124 15.3468 15.0898C15.4014 14.9672 15.4308 14.8348 15.4332 14.7006C15.4356 14.5664 15.4109 14.4331 15.3607 14.3086C15.3104 14.1841 15.2356 14.071 15.1408 13.976L12.4138 11.05L15.3398 8.32205Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 11C0 4.925 4.925 0 11 0C17.075 0 22 4.925 22 11C22 17.075 17.075 22 11 22C4.925 22 0 17.075 0 11ZM11 20C9.8181 20 8.64778 19.7672 7.55585 19.3149C6.46392 18.8626 5.47177 18.1997 4.63604 17.364C3.80031 16.5282 3.13738 15.5361 2.68508 14.4442C2.23279 13.3522 2 12.1819 2 11C2 9.8181 2.23279 8.64778 2.68508 7.55585C3.13738 6.46392 3.80031 5.47177 4.63604 4.63604 C5.47177 3.80031 6.46392 3.13738 7.55585 2.68508C8.64778 2.23279 9.8181 2 11 2C13.3869 2 15.6761 2.94821 17.364 4.63604C19.0518 6.32387 20 8.61305 20 11C20 13.3869 19.0518 15.6761 17.364 17.364C15.6761 19.0518 13.3869 20 11 20Z"
            fill="black"
          />
        </svg>

        {children}
      </div>
    </div>
  );
};

export default ConfirmationPopup;

