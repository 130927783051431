import React, { useState, useRef, useEffect, forwardRef } from "react";
import axios from "axios";
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import { jwtDecode } from "jwt-decode";
import { AspectRatio } from "../ui/aspect-ratio";
import { Button } from "../ui/button";
import { Slider } from "../ui/slider";
import Slider2 from "../ui/slider2";
import { get_base_uri } from "../../config";
import { Modal } from "../ui/Modal";
import uploadPc from "../../assets/upload-pc.png";
import books from "../../assets/book.png";

const uploadLabels = [
  "Selfie Video",
  "Variables Sheet",
  "Screen Recording (optional)",
];
const uploadImages = ["selfie.svg", "table.svg", "screen_recording.svg"];

const UploadButton = forwardRef(
  (
    {
      uploaded,
      onChange,
      progress,
      image,
      label,
      accept,
      activeMode,
      cancel,
      openSelectionModal,
    },
    ref
  ) => {
    const inputRef = useRef(null);

    const handleCancel = () => {
      cancel();
      // Reset file input
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.value = null;
      }
    };

    return (
      <div
        className="flex group relative cursor-pointer"
        onClick={() => {
          if (!activeMode) return;
          uploaded || progress ? handleCancel() : openSelectionModal();
        }}
      >
        <input
          ref={ref}
          accept={accept}
          type="file"
          className="h-full w-full invisible absolute inset-0"
          onChange={onChange}
        />
        <div
          className={`border relative z-30 px-6 py-5 w-[336px] rounded-[15px] flex flex-col gap-3 shadow-[0_0_4px_rgba(0,0,0,0.25)] 
    ${uploaded ? "border-lpurple" : "border-upload-border"}
    ${progress || uploaded
              ? "bg-lpurple text-white"
              : "bg-transparent text-black"
            }
    hover:outline hover:outline-lpurple`}
        >
          {uploaded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              className="absolute top-3 right-4"
              height="27"
              fill="none"
              viewBox="0 0 21 27"
            >
              <path
                fill="#fff"
                d="M3.445 13.66c0-1.89.769-3.672 2.167-5.01 1.398-1.337 3.26-2.074 5.236-2.074V9.86L16 4.93 10.848 0v3.28c-2.9 0-5.622 1.082-7.672 3.043C1.126 8.28 0 10.889 0 13.66c0 2.773 1.127 5.379 3.176 7.34l2.436-2.33c-1.398-1.339-2.167-3.116-2.167-5.01zM21 13.34c0-2.774-1.06-5.379-2.978-7.34L15.74 8.33a7.11 7.11 0 012.032 5.01c0 1.89-.722 3.672-2.032 5.01a6.828 6.828 0 01-4.909 2.074v-3.281L6 22.073 10.83 27v-3.28c2.718 0 5.27-1.082 7.192-3.04A10.43 10.43 0 0021 13.34z"
              ></path>
            </svg>
          ) : null}
          {progress > 0 && progress < 100 && (
            <div className="absolute inset-0 bg-transparent text-black flex items-center justify-center">
              <div className="relative w-[336px] h-full rounded-[15px] bg-opacity-75 text-black bg-white shadow-[0_0_4px_rgba(0,0,0,0.25)]">
                <div className="h-full bg-gray-300 rounded-[15px]">
                  <div
                    className="h-full bg-lpurple rounded-[15px]"
                    style={{
                      width: `${progress}%`,
                      animation: `progressAnimation ${progress / 100}s linear`,
                    }}
                  ></div>
                </div>
                <div
                  className="absolute top-0 right-0 p-2 font-bold"
                  style={{ color: progress < 50 ? "#000000" : "#000000" }}
                >
                  {progress}%
                </div>
              </div>
            </div>
          )}

          <div className="flex gap-6 relative">
            <img
              src={`/${image}`}
              alt={label.toLowerCase().replace(/ /g, "-")}
              height={60}
              width={60}
            />
            <div className="flex flex-col gap-0.5">
              <span className="font-lato font-bold text-base">{label}</span>
              <span className="font-lato font-normal text-sm">
                Animate your photo with text
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const UploadProject = ({
  setOpenModal,
  setProjectStatus,
  setParentProjectId,
  projectId = "",
  setProjectCreated2,
  isProjectCreated2,
  setProjectName,
  projectName,
  setProjectVideos,
  projectVideos,
  setRecordingVideoPath,
  recordingVideoPath,
  setSelfieVideoPath,
  selfieVideoPath,
}) => {
  const [progress, setProgress] = useState([0, 0, 0]);
  const videoRef = useRef(null);

  const [variableSheetPath, setVariableSheetPath] = useState("");
  const [videoPosition, setVideoPosition] = useState([20]);
  const [videoPosition2, setVideoPosition2] = useState([0, 100]);
  const [checked, setChecked] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([false, false, false]);
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [transcribing, setTranscribing] = useState(false);
  const [status, setStatus] = useState("");
  const [credit, setCredit] = useState("");
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [selection, setSelection] = useState(false);
  const [fromLibrary, setFromLibrary] = useState(false);
  const [active, setActive] = useState({ id: "", url: "" });
  const [libraryData, setLibraryData] = useState();
  const [file, setFile] = useState(null);

  const [ProjectId, setProjectId] = useState(projectId);
  const [boxIndex, setBoxIndex] = useState(1);
  const inputRefs = useRef([useRef(null), useRef(null), useRef(null)]);

  useEffect(() => {
    if (ProjectId === "" || ProjectId === null) {
      setIsProjectCreated(false);
      setProjectName("");
      setProjectVideos(false);
    }
    setProjectId(projectId);
    if (projectId) {
      fetchProjectDetails(projectId);
    }
  }, [projectId]);

  const fetchCreditsFromSubscription = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return;
    }
    const response = await axios.get(
      get_base_uri() + `/api/v1/billing/subscription-status/`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setCredit(response?.data?.credits);
  };

  useEffect(() => {
    const fetchLibraryVideo = async () => {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        return;
      }
      const response = await axios.get(get_base_uri() + `/api/v1/library`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      });
      setLibraryData(response.data.data);
    };
    fetchLibraryVideo();
    fetchCreditsFromSubscription();
  }, []);

  const fetchProjectDetails = async (projectId) => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(
        get_base_uri() + `/api/v1/projects/${projectId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      const project = response.data;

      setProjectName(project.project_name);
      setIsProjectCreated(true);
      setProjectCreated2(true);
      setProjectVideos([
        !!project.selfie_video,
        !!project.variables_sheet,
        !!project.screen_recording,
      ]);
      if (project.selfie_video) setSelfieVideoPath(project.selfie_video);
      if (project.screen_recording)
        setRecordingVideoPath(project.screen_recording);
      if (project.variables_sheet)
        setVariableSheetPath(project.variables_sheet);
      setStatus(project.status);
    } catch (error) { }
  };

  const createProject = async () => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      return;
    }

    let userId;
    try {
      const decodedToken = jwtDecode(token);
      userId = decodedToken.user_id;
    } catch (error) {
      return;
    }

    try {
      const response = await axios.post(
        get_base_uri() + "/api/v1/projects/",
        { user_id: userId, project_name: projectName },
        {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        }
      );
      const { id, project_name } = response.data;
      setProjectName(project_name);
      setProjectId(id);
      setProjectCreated2(true);
    } catch (error) { }
  };

  const uploadFile = async (file, library, videoId=null) => {
    const index = boxIndex;
    setProjectVideos((prev) => {
      if (!Array.isArray(prev)) prev = [];  
      const newFiles = [...prev];
      newFiles[index] = false;
      return newFiles;
    });

    if (!file && !library) {
      return;
    }
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();
    const fileFieldNames = [
      "selfie_video_file",
      "variables_sheet",
      "screen_recording_file",
    ];
    if (videoId) {
      if (index === 0) {
        formData.append("selfie_video_id", videoId);
      } else if (index === 2) {
        formData.append("recording_video_id", videoId);
      }
      else {
        formData.append(fileFieldNames[index], file);
      }
    } else {
      formData.append(fileFieldNames[index], file);
    }

    try {
      await axios.post(
        get_base_uri() + `/api/v1/projects/${ProjectId}/upload/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (evt) => {
            setProgress((prev) => {
              const newProgress = [...prev];
              const progress = Math.round((evt.loaded / evt.total) * 100);
              newProgress[index] = progress;
              return newProgress;
            });
          },
        }
      );
      setProgress((prev) => {
        const newProgress = [...prev];
        newProgress[index] = 0;
        return newProgress;
      });

      setProjectVideos((prev) => {
        const newFiles = [...prev];
        newFiles[index] = true;
        return newFiles;
      });
    } catch (error) { }
  };

  const handleFileChange = async (e, index) => {
    const file = e.target.files[0];

    if (index === 1) {
      const isValid = await validateCSV(file);
      if (!isValid) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Not Enough Credits.',
        });
        return;
      }
    }
    if (index === 0) setSelfieVideoPath(URL.createObjectURL(file));
    if (index === 1) setVariableSheetPath(URL.createObjectURL(file));
    if (index === 2) setRecordingVideoPath(URL.createObjectURL(file));
    uploadFile(file, false);
    setSelection(false);
    setBoxIndex(index);
  };

  const validateCSV = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: (results) => {
          const rows = results.data;
          const numberOfRows = rows.length - 1;
          if (numberOfRows > credit ) {
            resolve(false);
          } else {
            resolve(true);
          }
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          reject(false);
        }
      });
    });
  };

  const timeToPosition = (time) => {
    const totalTime = videoRef.current?.duration;
    return totalTime ? (time / totalTime) * 100 : 0;
  };

  const libraryVideoUpload = async () => {
    setLoadingVideo(true);
    const name = getFileName(active.url);
    try {
      const response = await fetch(active.url);
      const blob = await response.blob();
      const file = new File([blob], name, { type: blob.type });
      setSelfieVideoPath(URL.createObjectURL(file));
      await uploadFile(null, true, active.videoId);
    } catch (error) {
      console.error('Error fetching video. Please try again.');
    }
    setLoadingVideo(false);
    setFromLibrary(false);
    setSelection(false);
  };

  const positionToTime = (position) => {
    const totalTime = videoRef.current?.duration || 1;
    if (!totalTime) return 0;
    const timeInSeconds = position * (totalTime / 100);

    if (!isFinite(timeInSeconds)) return 0;
    return timeInSeconds;
  };

  const positionToTimeString = (position) => {
    const time = positionToTime(position);
    const minutes = Math.floor(time / 60);
    const seconds = Math.round((time % 60) * 100) / 100;
    return `${minutes ? minutes + " min " : ""}${seconds < 10 ? "0" : ""
      }${seconds} seconds`;
  };

  const selectionModalClose = (response) => {
    setSelection(response);
  };

  const handleSubmit = async () => {
    setTranscribing(true);
  
    if (videoPosition2[0] === videoPosition2[1]) {
      console.error("Start and end positions are the same.");
      setTranscribing(false);
      return;
    }
    const data = {
      background_video_timestamp: checked,
      startTime: checked ? positionToTime(videoPosition2[0]).toFixed(2) : null,
      endTime: checked ? positionToTime(videoPosition2[1]).toFixed(2) : null,
      status: "in_progress",
    };

    try {
      const token = localStorage.getItem("accessToken");
      await axios.put(
        get_base_uri() + `/api/v1/projects/${ProjectId}/update/status/`,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const checkStatusInterval = setInterval(async () => {
        const projectResponse = await axios.get(
          get_base_uri() + `/api/v1/projects/${ProjectId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const updatedProject = projectResponse.data;
        setStatus(updatedProject.status);

        if (updatedProject.status === "transcription_done") {
          setParentProjectId(ProjectId);
          clearInterval(checkStatusInterval);
          setTranscribing(false);
          setOpenModal(false);
          setProjectStatus("upload-variables");
          setOpenModal(true);
        }
      }, 5000); // Check every 5 seconds
    } catch (error) {
      setTranscribing(false);
    }
  };

  const openSelectionModal = (index) => {
    setBoxIndex(index);
    if (index === 1) {
      inputRefs.current[index].current.click();
      return;
    }
    setSelection(true);
  };

  const handleFileClick = () => {
    if (inputRefs.current[boxIndex]) {
      inputRefs.current[boxIndex].current.click();
    }
  };

  const handleLibraryFile = () => {
    setFromLibrary(true);
  };

  const getFileName = (url) => {
    const parts = url.split("/library/");
    return parts.length > 1 ? parts[1] : null;
  };
      const getLibFileName = (url, title) => {
      try {
        const path = new URL(url).pathname;
        return path.substring(path.lastIndexOf('/') + 1).split('.')[0];
      } catch (error) {
        return title;
      }
    };

  const activeVideoHandler = async (id, url, videoId) => {
    setActive({ id, url, videoId });
    // const name = getFileName(url);
    // try {
    //   const response = await fetch(url);
    //   const blob = await response.blob();
    //   const file = new File([blob], name, { type: blob.type });
    // } catch (error) {}
  };

  const closeLibraryFile = (response) => {
    setFromLibrary(response);
  };

  const handleCancel = (index) => {
    setProgress((prev) => {
      if (!Array.isArray(prev)) prev = [];
      const newProgress = [...prev];
      newProgress[index] = 0;
      return newProgress;
    });
    setProjectVideos((prev) => {
      if (!Array.isArray(prev)) prev = [];
      const newFiles = [...prev];
      newFiles[index] = false;
      return newFiles;
    });
    setBoxIndex(1);
    if (index === 0) setSelfieVideoPath("");
    if (index === 1) setVariableSheetPath("");
    if (index === 2) setRecordingVideoPath("");
  };

  const [playing, setPlaying] = useState(false);

  return (
    <div className="relative">
      <div className="flex gap-11 max-xl:flex-col max-xl:items-center">
        <div className="max-w-[336px] w-full flex xl:flex-col items-center justify-center max-xl:flex-wrap gap-[30px]">
          {[0, 1, 2].map((index) => (
            <UploadButton
              activeMode={isProjectCreated2}
              key={index}
              uploaded={projectVideos[index]}
              onChange={(e) => handleFileChange(e, index)}
              progress={progress[index]}
              image={uploadImages[index]}
              label={uploadLabels[index]}
              accept={index === 1 ? ".csv, .xlsx" : ".mp4, .mkv"}
              cancel={() => handleCancel(index)}
              openSelectionModal={() => {
                openSelectionModal(index);
              }}
              ref={inputRefs.current[index]}
            />
          ))}
        </div>
        <div className="flex flex-col gap-10">
          <h3 className="font-lato font-bold text-2xl text-black max-xl:text-center xl:ml-32">
            {projectId ? "Edit Project" : "Create New Project"}
          </h3>
          <div className="flex flex-col items-start gap-[38px] pl-14 xl:border-l border-[#BCBCBC] max-w-[723px] min-w-[723px] w-full max-lg:px-4">
            <div className="w-full max-w-[839px] border border-[#DCDCDC] flex items-center rounded-[14px] relative">
              <input
                className="px-2 py-4 w-full bg-transparent focus:first:outline-none"
                type="text"
                placeholder="Choose a name for your project"
                value={projectName}
                disabled={isProjectCreated2}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && !isProjectCreated2)
                    createProject();
                }}
                onChange={(event) => setProjectName(event.target.value)}
              />
              {!isProjectCreated2 && (
                <button
                  style={{ backgroundColor: "purple" }}
                  className="absolute right-4 cursor-pointer text-white px-3 py-1 rounded"
                  onClick={createProject}
                >
                  Create
                </button>
              )}
            </div>
            <div className="w-full flex flex-col">
              <AspectRatio
                ratio={16 / 9}
                className="bg-[#F3F1F1] w-full flex flex-col justify-center items-center rounded-xl relative"
              >
                {recordingVideoPath || selfieVideoPath ? (
                  <video
                    className="rounded-lg h-full object-contain"
                    onTimeUpdate={(event) => {
                      !videoRef.current?.paused &&
                        setVideoPosition([
                          timeToPosition(event.target.currentTime),
                        ]);
                    }}
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    onClick={() =>
                      videoRef.current?.paused
                        ? videoRef.current?.play()
                        : videoRef.current?.pause()
                    }
                    ref={videoRef}
                    src={recordingVideoPath || selfieVideoPath}
                  />
                ) : (
                  <div className="flex flex-col gap-5 items-center">
                    <span className="text-lg">
                      {loadingVideo ? (
                        "Loading..."
                      ) : (
                        <span>
                          <svg
                            width="90"
                            height="90"
                            viewBox="0 0 90 90"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="45" cy="45" r="45" fill="#D9D9D9" />
                            <path
                              d="M57.7502 34.2918C57.7502 32.0399 55.9188 30.2085 53.6668 30.2085H29.1668C26.9149 30.2085 25.0835 32.0399 25.0835 34.2918V54.7085C25.0835 56.9605 26.9149 58.7918 29.1668 58.7918H53.6668C55.9188 58.7918 57.7502 56.9605 57.7502 54.7085V47.9036L65.9168 54.7085V34.2918L57.7502 41.0967V34.2918Z"
                              fill="#999999"
                            />
                          </svg>
                          <p>Add a video</p>
                        </span>
                      )}
                    </span>
                  </div>
                )}
                {transcribing && (
                  <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
                    <div className="flex flex-col items-center justify-center">
                      <svg
                        style={{ marginBottom: '0.75rem', height: '3rem', width: '3rem', color: 'purple' }}
                        className="animate-spin"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                      >
                        <circle
                          style={{ opacity: 0.25 }}
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          style={{ opacity: 0.75, fill: 'purple' }}
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        ></path>
                      </svg>
                      <span style={{ color: 'purple' }}>
                        Transcribing the video ...
                      </span>
                    </div>
                  </div>
                )}
              </AspectRatio>
              <div className="flex justify-end w-full mt-6">
                <label
                  htmlFor="toggleB"
                  className="flex items-center gap-2.5 cursor-pointer"
                >
                  <div className="text-sm font-lato text-black font-medium">
                    Select Background part
                  </div>
                  <div className="relative switch">
                    <input
                      type="checkbox"
                      id="toggleB"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                      className="sr-only peer/toggle"
                    />
                    <span className="slider"></span>
                    <div
                      className={`dot absolute ${checked ? "right-1" : "left-1"
                        } `}
                    ></div>
                  </div>
                </label>
              </div>

              {(!!recordingVideoPath || !!selfieVideoPath) && (
                <div className="flex gap-3 ml-4 mr-4 mt-4 items-center">
                  <Button
                    style={{ backgroundColor: "purple" }}
                    className="hover:bg-lpurple"
                    onClick={() => {
                      playing
                        ? videoRef.current.pause()
                        : videoRef.current.play();
                    }}
                  >
                    <img
                      src={playing ? "/pause.png" : "/play.webp"}
                      height={playing ? 15 : 20}
                      width={playing ? 15 : 20}
                      alt={playing ? "Pause" : "Play"}
                    />
                  </Button>
                  <div className="relative w-full">
                    <Slider
                      className="absolute top-1/2 -translate-y-1/2"
                      onValueChange={(value) =>
                        setVideoPosition((prev) => {
                          if (videoRef.current) {
                            videoRef.current.pause();
                            videoRef.current.currentTime = positionToTime(
                              value[0]
                            );
                          }
                          return value;
                        })
                      }
                      value={videoPosition}
                      max={100}
                      step={0.05}
                      checked={checked}
                    />
                    {checked && (
                      <Slider2
                        className="absolute top-1/2 -translate-y-1/2"
                        onValueChange={(value) =>
                          setVideoPosition2((prev) => {
                            const [start, _] = prev;
                            const [newStart, newEnd] = value;
                            if (videoRef.current) {
                              videoRef.current.pause();
                              if (newStart !== start) {
                                setVideoPosition([newStart]);
                                videoRef.current.currentTime =
                                  positionToTime(newStart);
                              } else {
                                setVideoPosition([newEnd]);
                                videoRef.current.currentTime =
                                  positionToTime(newEnd);
                              }
                            }
                            return value;
                          })
                        }
                        minStepsBetweenThumbs={2}
                        value={videoPosition2}
                        max={100}
                        step={0.05}
                        checked={checked}
                      />
                    )}
                  </div>
                </div>
              )}
              {(!!recordingVideoPath || !!selfieVideoPath) && checked && (
                <>
                  <span className="ml-4">
                    Start time: {positionToTimeString(videoPosition2[0])}
                  </span>
                  <span className="ml-4">
                    End time: {positionToTimeString(videoPosition2[1])}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button
              style={{
                backgroundColor:
                  transcribing ||
                    status === "transcription_done" ||
                    !(projectVideos[0] && projectVideos[1])
                    ? "gray"
                    : "purple",
              }}
              className="hover:bg-lpurple/80 disabled:bg-Gray flex items-center gap-6 py-3.5 px-[54px] shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] rounded-[11px]"
              onClick={handleSubmit}
              disabled={
                !isProjectCreated2 ||
                !(projectVideos[0] && projectVideos[1]) ||
                transcribing ||
                status === "transcription_done"
              }
            >
              <img
                className="max-lg:w-[40px] h-auto"
                src="/mic.svg"
                width={36}
                height={36}
                alt="mic"
              />
              <span className="font-lato font-medium text-white md:text-base lg:text-lg">
                {transcribing
                  ? "Transcribing..."
                  : status === "transcription_done"
                    ? "Transcribed"
                    : "Transcribe the video"}
              </span>
            </button>
          </div>

          {transcribing && (
            <div className="text-sm font-lato text-gray-600 mt-2">
              This can take a few minutes....
            </div>
          )}
        </div>
        <Modal openModal={selection} setOpenModal={selectionModalClose}>
          <div className="h-[40vh]">
            <div className="flex h-full gap-4 items-center">
              <button
                className="border rounded p-4 flex gap-1 items-center"
                onClick={handleLibraryFile}
              >
                <img src={books} width={30} />
                From Library
              </button>
              <button
                className="border rounded p-4 flex gap-1 items-center"
                onClick={handleFileClick}
              >
                <img src={uploadPc} width={30} /> From Computer
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          openModal={fromLibrary}
          isLibraryModal={true}
          setOpenModal={closeLibraryFile}
        >
          <div className="p-5 relative">
            {loadingVideo && (
              <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
                <div className="flex flex-col items-center justify-center">
                  <svg
                    style={{ marginBottom: '0.75rem', height: '3rem', width: '3rem', color: 'purple' }}
                    className="animate-spin"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                  >
                    <circle
                      style={{ opacity: 0.25 }}
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      style={{ opacity: 0.75, fill: 'purple' }}
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  <span style={{ color: 'purple' }}>
                    Uploading video...
                  </span>
                </div>
              </div>
            )}
            <div className={`flex flex-wrap ${loadingVideo ? 'opacity-50' : ''}`}>
              {libraryData &&
                libraryData.length > 0 &&
                libraryData.map((item, index) => {
                  return (
                    <div key={index} className="w-1/4 p-2">
                      <button
                        className={`w-full border-4 rounded ${active.id === index + 1
                          ? "border-blue-500"
                          : " border-transparent"
                          }`}
                        onClick={() =>
                          activeVideoHandler(index + 1, item.video_file, item.id)
                        }
                      >
                        <video className="w-full" src={item.video_file}></video>
                      </button>
                      <div className="text-center mt-2">{getLibFileName(item.video_file, item.title)}</div>
                    </div>
                  );
                })}
            </div>
            <div className="flex h-full gap-4 items-center">
              <button
                className="border rounded p-4 mt-4"
                onClick={libraryVideoUpload}
                disabled={loadingVideo}
              >
                Upload
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default UploadProject;
