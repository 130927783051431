import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ConfirmationPopup from "../../components/ui/confirmationPopup";

const Project = ({
  project,
  processStatus,
  deleteProject,
  setProjectId,
  setOpenModal,
  setProjectStatus,
  videoUrl,
}) => {
  const [callback, buttonText, buttonColor] = processStatus(project);
  const { status, thumbnail = null, step_names } = project;
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  function getProgressClasses(progress) {
    const classNames = [];

    if (progress <= 0) {
      for (let i = 0; i < 5; i++) {
        classNames.push("bg-step-inactive");
      }
    } else {
      const activeSteps = Math.min(progress, 5);
      for (let i = 0; i < 5; i++) {
        if (i < activeSteps) {
          classNames.push("bg-purple-600");
        } else {
          classNames.push("bg-step-inactive");
        }
      }
    }

    return classNames;
  }

  const handleProjectClick = () => {
    setProjectId(project.id);
    if (status === "awaiting_upload") {
      setProjectStatus("upload-project");
      setOpenModal(true);
    } else {
      setProjectStatus("upload-variables");
      setOpenModal(true);
    }
  };

  const shouldShowDownloadButton = ["generation_done", "completed"].includes(
    status
  );

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteProject(project.id);
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <div
        className="relative hover:-translate-y-2 duration-300 group max-w-[274px] w-full px-3.5 py-[9px] cursor-pointer rounded-2xl hover:bg-white rounded-lg hover:shadow-[0_0_10px_rgba(0,0,0,0.25)]"
        onClick={handleProjectClick}
      >
        <div className="absolute right-4 top-4 items-center gap-2 hidden z-20 group-hover:flex flex-col">
          <div className="size-7 rounded-[6px] bg-white flex items-center justify-center cursor-pointer transition duration-200 hover:bg-lpurple hover:text-white">
            <img src="/eye.svg" alt="eye" height="20" width="16" />
          </div>

          <div
            onClick={handleDeleteClick}
            className="size-7 rounded-[6px] bg-white flex items-center justify-center cursor-pointer transition duration-200 hover:bg-lpurple hover:text-white"
          >
            <img src="/trash.svg" alt="trash" height={20} width={16} />
          </div>

          {shouldShowDownloadButton && (
            <a
              href={videoUrl}
              className="size-7 rounded-[6px] bg-white flex items-center justify-center cursor-pointer transition duration-200 hover:bg-lpurple hover:text-white"
            >
              <img src="download.svg" alt="download" height={20} width={16} />
            </a>
          )}
        </div>
        <div className="w-full relative z-10">
          <div className="relative flex items-center justify-center w-full">
            {status === "awaiting_upload" && (
              <img
                className="absolute w-16 h-16 z-20"
                src="/upload.png"
                alt="file upload icon"
                style={{
                  filter:
                    "invert(23%) sepia(98%) saturate(7476%) hue-rotate(271deg) brightness(91%) contrast(106%)",
                }}
              />
            )}

            {["in_progress", "transcribing"].includes(status) && (
              <div className="absolute w-12 h-12 z-10 flex flex-col items-center justify-center">
                <svg
                  className="animate-spin h-12 w-12 text-white"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
              </div>
            )}
            {[
              "starting_generation",
              "video_generation_in_progress",
              "transcription_done",
            ].includes(status) && (
              <div className="absolute w-12 h-12 z-10">
                <CircularProgressbarWithChildren
                  background
                  styles={buildStyles({
                    backgroundColor: "rgba(0,0,0,0.6)",
                    textColor: "#fff",
                    pathColor: "#7559FF",
                    trailColor: "rgba(255,255,255,0.6)",
                  })}
                  strokeWidth={15}
                  value={
                    status === "starting_generation"
                      ? 60
                      : status === "video_generation_in_progress"
                      ? 80
                      : status === "transcription_done"
                      ? 40
                      : 0
                  }
                >
                  <div className="text-white font-bold text-lg text-center">
                    <p>
                      <span className="text-lpurple">
                        {status === "starting_generation"
                          ? 3
                          : status === "video_generation_in_progress"
                          ? 4
                          : status === "transcription_done"
                          ? 2
                          : ""}
                      </span>
                      /5
                    </p>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            )}
            <div className="relative w-full">
              {!thumbnail && (
                <div
                  className=" w-full rounded-[5px] bg-gray-200"
                  style={{ height: "8.5rem" }}
                ></div>
              )}
              <img
                className={`h-full rounded-[5px] ${!thumbnail ? "hidden" : ""}`}
                src={
                  thumbnail
                    ? thumbnail
                    : "http://api.customscale.ai/media/b4224727-9cbf-4e0a-9eb4-d21127a57b54/thumbnails/b4224727-9cbf-4e0a-9eb4-d21127a57b54.png"
                }
                alt="project thumbnail"
              />
              {project.progress >= 0 &&
                ![
                  "transcribing",
                  "in_progress",
                  "starting_generation",
                  "video_generation_in_progress",
                  "transcription_done",
                  "awaiting_upload",
                  "generation_done",
                ].includes(status) && (
                  <div className="absolute w-full bottom-0 grid grid-cols-5 gap-2">
                    {getProgressClasses(project.progress).map(
                      (className, index) => (
                        <div
                          key={index}
                          className={`${className} h-2.5 ${
                            index === 0
                              ? "rounded-l-[4.5px]"
                              : index === 4
                              ? "rounded-r-[4.5px]"
                              : ""
                          }`}
                        />
                      )
                    )}
                  </div>
                )}
              {["in_progress", "transcribing"].includes(status) && (
                <div className="absolute w-full bottom-0 font-lato text-xs text-[#474747] font-normal bg-gray-300 px-1 py-1">
                  {status === "in_progress"
                    ? "In Progress"
                    : status === "transcribing"
                    ? "Transcribing..."
                    : ""}
                </div>
              )}
              {status === "awaiting_upload" && (
                <div className="absolute w-full bottom-0 font-lato text-xs text-[#474747] font-normal bg-gray-300 px-1 py-1">
                  Waiting for Upload...
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`flex items-center justify-start p-2 w-full`}>
          <span className="text-lg font-lato font-normal">
            {project.project_name}
          </span>
        </div>
      </div>

      <ConfirmationPopup
        openModal={isDeleteModalOpen}
        setOpenModal={setIsDeleteModalOpen}
      >
        <p>Are you sure you want to delete this project?</p>
        <div className="flex justify-center space-x-4 mt-10">
          <button
            onClick={handleConfirmDelete}
            className="px-4 py-2 bg-red-600 text-white rounded-md"
          >
            Agree
          </button>
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-300 rounded-md"
          >
            Cancel
          </button>
        </div>
      </ConfirmationPopup>
    </>
  );
};

export default Project;
