import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Image from '../ui/Image'; 
import HomeIcon from '../ui/svg/HomeIcon';
import LayerIcon from '../ui/svg/SubscriptionIcon';
import SubscriptionIcon from '../ui/svg/SubscriptionIcon';

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <aside className="w-full max-w-[295px] min-w-[295px] px-6 py-[22px] flex flex-col items-center lg:border-r border-stroke-gray h-full">
       <a href="/">
      < Image src="/logo-2.svg" width={194} height={53} alt="logo" />
      </a>
      <div className="w-full flex flex-col gap-4 mt-10">
        <IconMenuCard
          link="/"
          img={<HomeIcon active={pathname === "/"} />}
          text="Home"
          active={pathname === "/"}
        />
        <IconMenuCard
          link="/library"
          img={<LayerIcon active={pathname === "/library"} />}
          text="My Library"
          active={pathname === "/library"}
        />
        <IconMenuCard
          link="/packages"
          img={<SubscriptionIcon active={pathname === "/packages"} />}
          text="Subscription"
          active={pathname === "/packages"}
        />
      </div>
    </aside>
  );
};

export default Sidebar;

const IconMenuCard = ({ img, text, link }) => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Link
      to={link}
      className={`${
        pathname === link ? "bg-[#F5F3FF] text-lpurple" : "text-black hover:bg-gray-200 hover:text-lpurple"
      } flex w-full gap-4 px-[18px] py-3.5 rounded-[12px] font-lato text-base font-normal cursor-pointer transition-all duration-300`}
      
    >
      {img} <p>{text}</p>
    </Link>
  );
};
