import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Stages from "../../components/progress";
import { logout } from "../../utils/logout";
import { LoadUserInfo } from "../../utils/userInfo";
import { get_base_uri } from "../../config";

export default function DownloadPage() {
  const [projectName, setProjectName] = useState("");
  const [userId, setUserId] = useState("");
  const { ProjectId } = useParams();
  const decodedProjectId = decodeURIComponent(ProjectId);
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState(null);
  const [resultUrl, setResultUrl] = useState("");
  const [_id, setId] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const projectPage = () => {
    if (userId) {
      navigate(`/user/${userId}`);
    }
  };

  const userInfoState = LoadUserInfo();

  useEffect(() => {
    if (
      userInfoState.loaded === "true" &&
      userInfoState.logged_out === "true"
    ) {
      navigate("/");
    }
  }, [userInfoState, navigate]);

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", `${_id}_output.${fileUrl.split(".").pop()}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const fetchProject = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.get(
        get_base_uri() + `/api/v1/projects/${ProjectId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const project = response.data;
      setProjectName(project.project_name);
      setUserId(project.user_id);
      setThumbnail(project.thumbnail);
      setId(project._id);
      setResultUrl(project.output); // Use project.output instead of result_url
      setStatus(project.status);
      if (project.status !== "generation_done") {
        setTimeout(fetchProject, 5000); // Reload the page every 5 seconds if status is not 'generation_done'
      } else {
        setLoading(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (decodedProjectId) {
      fetchProject();
    }
  }, [decodedProjectId]);

  const options = [
    { title: "Buy Credits", action: () => navigate("/credits/") },
    { title: "My Projects", action: () => navigate(`/user/${userId}`) },
    { title: "Logout", action: () => logout(navigate) },
  ];

  return (
    <div className="relative h-[100vh] w-full flex flex-col bg-slate-50 items-center">
      <div className="w-50% flex flex-col items-center w-[50%]">
        <Stages progress={2} projectId={decodedProjectId} status={status} />
      </div>

      {loading ? (
        <div className="flex items-center justify-center h-full">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
          <style>{`
            .loader {
              border-top-color: #3498db;
              animation: spinner 1.5s linear infinite;
            }
            @keyframes spinner {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
          `}</style>
        </div>
      ) : (
        <>
          {status === "generation_done" && (
            <>
              <div className="relative w-1/2 mt-32 flex justify-start items-center">
                <span className="absolute top-1/2 z-20 left-1/2 text-lg text-white -translate-x-1/2">
                  Output Generated
                </span>
                <div className="absolute top-0 left-0 w-full h-full bg-slate-900 opacity-50 z-10"></div>
                <img
                  className="absolute top-0 left-0 w-full h-full z-0"
                  src={thumbnail}
                  alt="project thumbnail"
                />
              </div>

              <button
                className="px-4 py-2 bg-mypurple hover:bg-lpurple text-white rounded-md mt-5"
                onClick={() => {
                  handleDownload(resultUrl);
                }}
              >
                Download
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}
