import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function CreditsPage() {
  const navigate = useNavigate();
  const params = useParams();
  const user_id = decodeURIComponent(params.user_id);
  const price = 0.1;
  const [credits, setCredits] = useState(10);

  const onBuyCredits = () => {
    navigate("/payment", { state: { credits, price } });
  };

  const userInfoState = LoadUserInfo();

  useEffect(() => {
    if (userInfoState.loaded === "true" && userInfoState.logged_out === "true") {
      navigate("/");
    }
  }, [userInfoState, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-md rounded-lg p-8 w-full max-w-md">
        <h2 className="text-2xl font-semibold text-gray-700 mb-6">Buy Credits</h2>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label className="text-sm font-medium text-gray-700" htmlFor="currentCredits">Current Credits</label>
            <input
              id="currentCredits"
              value={credits}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (value >= 10) setCredits(value);
              }}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              type="number"
              step="10"
              min="10"
            />
          </div>
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-700">Price per credit:</span>
            <span className="text-sm text-gray-700">${price}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-sm font-medium text-gray-700">Total Price:</span>
            <span className="text-sm text-gray-700">${credits * price}</span>
          </div>
          <button
            onClick={onBuyCredits}
            className="mt-4 w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Buy Credits
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreditsPage;

function LoadUserInfo() {
  // Simulate loading user info
  return {
    loaded: "true", // "true" or "false" depending on the state
    logged_out: "false", // "true" or "false" depending on the state
  };
}
