import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import DownloadSuccessful from "../../../components/project/DownloadSuccessful";
import UploadProject from "../../../components/project/UploadProject";
import UploadVariables from "../../../components/project/UploadVariables";
import { Modal } from "../../../components/ui/Modal";
import { Button } from "../../../components/ui/button";
import Project from "../projects"; // Adjust the path as necessary
import { logout } from "../../../utils/logout";
import { get_base_uri } from "../../../config";

export default function Projects() {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const decodedUserId = decodeURIComponent(user_id);
  const [projects, setProjects] = useState([]);
  const [ProjectId, setProjectId] = useState(null);
  const [projectStatus, setProjectStatus] = useState("upload-project");
  const [openModal, setOpenModal] = useState(false);
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [projectName, setprojectName] = useState("");
  const [projectVideos, setProjectVideos] = useState([false, false, false]);
  const [selfieVideoPath, setSelfieVideoPath] = useState("");
  const [recordingVideoPath, setRecordingVideoPath] = useState("");
  const [downloadVideoUrl, setVideoUrl] = useState("");
  const fetchProjects = async (user_id) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const response = await axios.get(get_base_uri() + "/api/v1/projects/", {
          headers: { Authorization: `Bearer ${token}` },
          withCredentials: true,
        });
        setProjects(response.data.data || []);
      } else {
      }
    } catch (error) {}
  };

  const getStatusText = (project) => {
    const { step_names } = project;

    switch (project.status) {
      case "generation_done":
        return "Processed";
      case "transcription_done":
        return "Transcription Processed";
      case "uploaded":
        return "Generating transcription";
      case "processing":
        return `${step_names[project.progress]} (${project.progress}/${
          step_names.length
        })`;
      case "awaiting_upload":
      default:
        return "Awaiting Upload";
    }
  };

  const navigateToProject = (project_id, page) => {
    navigate(`/project/${project_id}/${page}`);
  };

  const processStatus = ({ id, status, result_url = "" }) => {
    switch (status) {
      case "transcription_done":
        return [() => setProjectStatus("upload-variables"), "Set variables"];
      case "awaiting_upload":
        return [() => setProjectStatus("upload-project"), "Upload files"];
      case "generation_done":
        return [() => setProjectStatus("download"), "Download"];
      default:
        return [null, null];
    }
  };

  useEffect(() => {
    fetchProjects(decodedUserId);
    const intervalId = setInterval(() => {
      fetchProjects(decodedUserId);
    }, 10000);

    return () => clearInterval(intervalId);
  }, [decodedUserId]);

  const token = localStorage.getItem("accessToken");
  const deleteProject = async (project_id) => {
    try {
      await axios.delete(get_base_uri() + `/api/v1/projects/${project_id}`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
      });
      fetchProjects(decodedUserId);
    } catch (error) {}
  };

  const handleLogout = async () => {
    axios.defaults.withCredentials = true;
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/logout`)
      .then((response) => {
        if (response.status === 200) navigate("/");
      })
      .catch((error) => {});
  };

  const handleCreateNewProject = () => {
    setProjectId(null);
    setProjectStatus("upload-project");
    setOpenModal(true);
    setIsProjectCreated(false);
    setprojectName("");
    setProjectVideos([false, false, false]);
    setSelfieVideoPath("");
    setRecordingVideoPath("");
  };

  return (
    <div className="flex container flex-col items-center">
      <div className="flex justify-end w-full mt-9">
        <Button
          className="rounded-[6px] flex items-center gap-2.5 px-3.5 py-0 bg-lpurple text-white text-center hover:bg-lpurple/80 disabled:bg-slate-800 disabled:cursor-not-allowed disabled:text-white/80"
          style={{ backgroundColor: "purple" }}
          onClick={handleCreateNewProject}
        >
          <img
            alt="plus"
            loading="lazy"
            width="16"
            height="16"
            decoding="async"
            data-nimg="1"
            src="/plus-white.svg"
          />
          <span className="font-lato font-bold text-center text-lg text-white">
            Create new project
          </span>
        </Button>
        <Modal openModal={openModal} setOpenModal={setOpenModal}>
          {projectStatus === "upload-project" ? (
            <UploadProject
              setParentProjectId={setProjectId}
              setOpenModal={setOpenModal}
              setProjectStatus={setProjectStatus}
              projectId={ProjectId}
              setProjectCreated2={setIsProjectCreated}
              isProjectCreated2={isProjectCreated}
              setProjectName={setprojectName}
              projectName={projectName}
              setProjectVideos={setProjectVideos}
              projectVideos={projectVideos}
              setRecordingVideoPath={setRecordingVideoPath}
              recordingVideoPath={recordingVideoPath}
              setSelfieVideoPath={setSelfieVideoPath}
              selfieVideoPath={selfieVideoPath}
            />
          ) : projectStatus === "upload-variables" ? (
            <UploadVariables
              setdownloadVideoURl={setVideoUrl}
              setProjectStatus={setProjectStatus}
              projectStatus={projectStatus}
              projectId={ProjectId}
            />
          ) : (
            <DownloadSuccessful />
          )}
        </Modal>
      </div>
      <div className="mt-3 pb-20 grid gap-3 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
        {Array.isArray(projects) &&
          projects.map((project) => (
            <Project
              key={project.id}
              project={project}
              setProjectId={setProjectId}
              processStatus={processStatus}
              getStatusText={getStatusText}
              deleteProject={deleteProject}
              setOpenModal={setOpenModal}
              setProjectStatus={setProjectStatus}
              videoUrl={project.output}
            />
          ))}
      </div>
    </div>
  );
}
