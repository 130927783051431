import React, { useEffect, useRef, useState } from "react";
import Image from "./ui/Image"; // Adjust the import path according to your project structure
import Drawer from "./ui/Drawer";
import Sidebar from "./layout/Sidebar";
import { useNavigate } from "react-router-dom";
import { get_base_uri } from "../config";
import axios from "axios";
const Navbar = ({ title, credits = null }) => {
  const navigate = useNavigate();
  const [dropdown, setDropdown] = useState(false);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState();

  const dropdownRef = useRef(null);

  const options = [
    { title: "My Projects" },
    {
      title: "Logout",
      action: () => {
        localStorage.clear();
        navigate("/login");
      },
    },
    {
      title: "Buy Credits",
      action: () => {
        navigate("/credits");
      },
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      const currentElement = dropdownRef.current;

      if (currentElement && !currentElement.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/login");
      return;
    }

    const subscriptionStatus = async () => {
      try {
        const response = await axios.get(
          get_base_uri() + `/api/v1/billing/subscription-status/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setStatus(response.data);
      } catch (error) {
        console.error("Authentication check failed:", error);
        navigate("/login");
      }
    };
    subscriptionStatus();
  }, [navigate]);

  return (
    <>
      <nav className=" pr-8 container py-[18px] border-b border-stroke-gray flex items-center justify-between">
        <div
          className="cursor-pointer lg:invisible"
          onClick={() => setOpen(true)}
        >
          <Image src="/burger.svg" width={30} height={30} alt="logo" />
        </div>
        <div className="flex items-center gap-2.5 md:gap-7 lg:gap-[52px]">
          <div className="flex items-center gap-3">
            {/* <Image src="https://img.icons8.com/flat-round/64/cheap-2--v1.png" width={51} height={45} alt="coin" /> */}
            <p className="font-lato font-normal text-xl text-black">
              {status ? status.credits : 0} Credits
            </p>
          </div>

          <div
            className="w-12 h-12 rounded-full bg-purple-600 font-lato font-bold text-2xl text-white flex items-center justify-center cursor-pointer"
            onClick={() => setDropdown(!dropdown)} // Toggle dropdown visibility
          >
            A
          </div>
        </div>

        {dropdown && (
          <div
            ref={dropdownRef}
            className="absolute top-16 overflow-hidden right-3 flex flex-col rounded-md bg-slate-200 shadow-lg z-20"
          >
            {options.map((option) => (
              <button
                key={option.title}
                className="hover:bg-slate-100 text-sm px-4 py-2 text-end"
                onClick={option.action} // Handle action if defined
              >
                {option.title}
              </button>
            ))}
          </div>
        )}
      </nav>
      <Drawer open={open} setOpen={setOpen} side="left">
        <Sidebar />
      </Drawer>
    </>
  );
};

export default Navbar;

const CustomScaleLogo = () => {
  return (
    <div>
      <Image src="/logo.svg" width={251} height={103} alt="logo" />
    </div>
  );
};
